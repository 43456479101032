
.form-success-feature-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius8);
    border: 2px
  }
  .feature-card-icon {
    fill: var(--dl-color-primary1-blue80);
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    margin-right: var(--dl-space-space-oneandhalfunits);
  }
  .feature-card-container {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .feature-card-text {
    font-family: "Raleway";
    font-weight: 600;
    line-height: 28px;
  }
  .form-success-text {
    color: var(--dl-color-gray-800);
    line-height: 1.6;
    align-items: center;
    text-align: center;
    font-weight: bold;
  }
  @media(max-width: 767px) {
    .feature-card-feature-card {
      flex-direction: row;
    }
    .feature-card-container {
      flex-direction: column;
    }
  }
  @media(max-width: 479px) {
    .feature-card-container {
      flex-direction: column;
    }
  }
  